/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { PaypalSmartButtonStyleConfigType } from '../../type/Paypal.type';

import './PaypalSmartButtons.style';

export class PaypalSmartButtonsComponent extends PureComponent {
    static propTypes = {
        page: PropTypes.string.isRequired,
        handleOnClick: PropTypes.func.isRequired,
        handleOnError: PropTypes.func.isRequired,
        handleOnApprove: PropTypes.func.isRequired,
        handleOnCancel: PropTypes.func.isRequired,
        handleCreateOrder: PropTypes.func.isRequired,
        paypal: PropTypes.shape({
            Buttons: PropTypes.func
        }),
        style: PaypalSmartButtonStyleConfigType.isRequired
    };

    static defaultProps = {
        paypal: null
    };

    renderButtons() {
        const {
            style,
            handleOnClick,
            handleOnError,
            handleOnApprove,
            handleOnCancel,
            handleCreateOrder,
        } = this.props;

        const path = window.location.pathname;
        const storeCode = path.split('/')[1];
        const locale = storeCode === 'fr' ? 'fr_CA' : 'en_CA';

        const initialOptions = {
            "client-id": "AU94ecLcFy5e9vA7KaeKzJKmVfZPkh2_1r3BAiuRQIz6MCJ2NR3rIvgBBRKtjZO58KyvRwGr0zNgbQh6",
            "currency": "CAD",
            "locale": locale,
            "intent": "capture",
        }

        return (
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons
                    fundingSource="paypal"
                    onClick={handleOnClick}
                    createOrder={handleCreateOrder}
                    onApprove={handleOnApprove}
                    onCancel={handleOnCancel}
                    onError={(err) => {
                        console.error('PayPal Buttons onError:', err);
                        handleOnError(err);
                    }}
                    style={style}
                />
            </PayPalScriptProvider>
        );
    }

    render() {
        const { page } = this.props;

        return (
            <div
                id={`${page}-buttons`}
                block="PaypalSmartButtons"
            >
                {this.renderButtons()}
            </div>
        );
    }
}

export default PaypalSmartButtonsComponent;
