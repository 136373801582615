/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/PaypalExpress/Query/PaypalOnAuthorization/Mutation */
export class PaypalOnAuthorizationMutation {
    getPaypalOnAuthorization(cartId, payerId, paymentToken) {
        return new Field('paypalOnAuthorization')
            .addArgument('cartId', 'String!', cartId)
            .addArgument('payerId', 'String!', payerId)
            .addArgument('paymentToken', 'String!', paymentToken)
            .addFieldList(this.getPaypalOnAuthorizationFields());
    }

    getPaypalOnAuthorizationFields() {
        return [
            'redirectUrl'
        ];
    }
}

export default new PaypalOnAuthorizationMutation();
