/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/PaypalExpress/Query/PaypalOnCancel/Mutation */
export class PaypalOnCancelMutation {
    onCancel() {
        return new Field('paypalOnCancel')
            .addFieldList(this.getPaypalOnCancelFields());
    }

    getPaypalOnCancelFields() {
        return [
            'status',
            'message'
        ];
    }
}

export default new PaypalOnCancelMutation();
