/**
 * Paypal Express compatibility for ScandiPWA
 * @copyright Scandiweb, Inc. All rights reserved.
 */

import { Field } from 'Util/Query';

/** @namespace Scandiweb/PaypalExpress/Query/PaypalExpressToken/Mutation */
export class PaypalExpressTokenMutation {
    getPaypalExpressToken(input) {
        return new Field('createPaypalExpressToken')
            .addArgument('input', 'PaypalExpressTokenInput!', input)
            .addFieldList(this.getPaypalExpressTokenFields());
    }

    getPaypalExpressTokenFields() {
        return [
            'token'
        ];
    }
}

export default new PaypalExpressTokenMutation();
